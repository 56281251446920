export default class FeaturedPrograms {
  constructor() {
    this.blocks = $('.featured-programs');

    this.blocks.each((_, block) => {
      this.initBlock($(block));
    });
  }

  initBlock(block) {
    const dots = block.find('.dot'),
      programs = block.find('.program'),
      next = block.find('.featured-programs__next'),
      prevButton = block.find('.slick-prev'),
      nextButton = block.find('.slick-next');

    dots.on('click', (event) => {
      const dot = $(event.currentTarget);
      changeSlide(dot.index());
    });

    next.on('click', () => {
      const currentIndex = block.find('.program.active').index(),
        nextIndex = currentIndex === programs.length - 1 ? 0 : currentIndex + 1;

      changeSlide(nextIndex);
    });

    prevButton.on('click', () => {
      const currentIndex = block.find('.program.active').index(),
        prevIndex = currentIndex - 1 === -1 ? programs.length - 1 : currentIndex - 1;

      changeSlide(prevIndex);
    });

    nextButton.on('click', () => {
      const currentIndex = block.find('.program.active').index(),
        nextIndex = currentIndex === programs.length - 1 ? 0 : currentIndex + 1;

      changeSlide(nextIndex);
    });

    function changeSlide(index) {
      dots.removeClass('active');
      programs.removeClass('active');

      dots.eq(index).addClass('active');
      programs.eq(index).addClass('active');

      const nextProgramIndex = index === programs.length - 1 ? 0 : index + 1,
        nextTitle = programs.eq(nextProgramIndex).find('h3').text();

      next.find('h5').text(nextTitle);
    }
  }
}