export default class Partners {
  constructor() {
    this.partners = $('.partners__list--mobile');

    this.initSlider();
  }

  initSlider() {
    this.partners.slick({
      autoplay: true
    });

    $(window).on('load resize', () => {
      const windowWidth = $(window).width();

      if (this.partners && windowWidth < 600) {
        this.partners.prev().css('display', 'none');
        this.partners.css('display', 'block');
      } else if (this.partners) {
        this.partners.css('display', 'none');
        this.partners.prev().css('display', 'flex');
      }
    });
  }
}