export default class GridPages {
  constructor() {
    this.loadMore = $(".grid-load-more button")

    this.loadMore.on("click", (e) => {
      const parent = $(e.currentTarget).parents(".paginated-grid")

      const lastShown = $(parent)
        .find(".video-card.show, .page-card.show")
        .last()

      lastShown.nextAll(".video-card, .page-card").slice(0, 20).addClass("show")
    })
  }
}
