export default class Runners {
  constructor() {
    this.runners = $('.hero__runner');

    setInterval(() => {
      this.swapRunner();
    }, 5000);
  }

  swapRunner() {
    const activeRunner = this.runners.filter('.active');
    activeRunner.removeClass('active');

    const activeRunnerIndex = activeRunner.index(),
      nextRunner = activeRunnerIndex === this.runners.length - 1 ? 0 : activeRunnerIndex + 1;

    this.runners.eq(nextRunner).addClass('active');
  }
}