import qs from 'qs';

export default class News {
  constructor() {
    this.categoryFilter = $('.category-filter select');
    this.raceFilter = $('.race-filter select');

    this.type = $('.category-filter').data('type');

    this.filters = {
      category: '',
      program: qs.parse(location.search.replace('?', '')).program,
      race: qs.parse(location.search.replace('?', '')).race
    };

    this.categoryFilter.on('change', event => {
      this.filters.category = event.target.value;
      this.changeFilter();
    });

    this.raceFilter.on('change', event => {
      this.filters.race = event.target.value;
      this.changeFilter();
    });
  }

  changeFilter() {
    if (this.type) {
      $.ajax({
        url: '/news.js',
        method: 'GET',
        data: {
          collection: this.filters.category
        },
        success: () => {},
        error: () => {
          console.error('Failed to load news posts');
        }
      });
    } else {
      $.ajax({
        url: '/blog.js',
        method: 'GET',
        data: {
          collection: this.filters.category,
          program: this.filters.program,
          race: this.filters.race
        },
        success: () => {},
        error: () => {
          console.error('Failed to load news posts');
        }
      });
    }
  }
}