export default class Gallery {
  constructor() {
    this.sliders = $('.gallery__slider');
    this.captions = $('.slide__dropdown');

    this.captions.each((index, caption) => {
      $(caption).on('click', () => {
        $(caption).find('.slide__indicator').toggleClass('open');
        $(caption).next().slideToggle();
      });
    });

    $(window).on('resize', () => {
      if ($(window).innerWidth() >= 768) {
        $('.slide__caption').slideDown();
      } else {
        $('.slide__caption').slideUp();
        $('.slide__indicator').removeClass('open');
      }
    });

    this.sliders.each((index, slider) => {

      $(slider).on('beforeChange', function (event, slick, currentSlide) {
        if ($(window).innerWidth() < 768) {
          $('.slide__indicator').removeClass('open');
          $('.slide__caption').slideUp();
        }
      });

      $(slider).slick({
        edgeFriction: 0.5,
        slidesToShow: 1,
        waitForAnimate: false,
        centerMode: true,
        centerPadding: '60px',
        variableWidth: true,
        infinite: true,
      });
    })
  }
}