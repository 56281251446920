export default class PageCards {
  constructor() {
    $(".page-filters select").on("change", (event) => {
      const parent = $(event.target).parents(".page-cards")
      const cards = parent.find(".page-card")
      const loadMore = parent.find(".grid-load-more")

      const value = event.target.value

      if (!value) {
        cards.removeClass("show")
        cards.slice(0, 20).addClass("show")
        loadMore.removeClass("hide")
      } else {
        cards.each((_, card) => {
          const $card = $(card)
          const categories = `${$card.data("categories")}`.split(",")
          loadMore.addClass("hide")

          if (categories.includes(value)) {
            $card.addClass("show")
          } else {
            $card.removeClass("show")
          }
        })
      }
    })
  }
}
