import "core-js/stable"
import "regenerator-runtime/runtime"
import objectFitImages from "object-fit-images"
import "slick-carousel"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import Accordions from "./accordions"
import Dropdowns from "./dropdowns"
import Menu from "./menu"
import Events from "./events"
import FeaturedNews from "./featured-news"
import FeaturedPrograms from "./featured-programs"
import FeaturedRaces from "./featured-races"
import Gallery from "./gallery"
import GridPages from "./grid-pages"
import MoonChallenge from "./moon-challenge"
import News from "./news"
import PageCards from "./page-cards"
import Partners from "./partners"
import ProgramHero from "./program-hero"
import Formbuilder from "./formbuilder"
import Video from "./video"
import VideoGallery from "./video-gallery"
import Runners from "./runners"
import Search from "./search"

objectFitImages()

new Accordions()
new Dropdowns()
new Menu()
new Events()
new FeaturedNews()
new FeaturedPrograms()
new FeaturedRaces()
new Gallery()
new GridPages()
new MoonChallenge()
new News()
new PageCards()
new Partners()
new ProgramHero()
new Formbuilder()
new Video()
new VideoGallery()
new Runners()
new Search()
