export default class FeaturedNews {
  constructor() {
    this.news = $('.featured-news__slider');

    this.initSliders();
  }

  initSliders() {
    this.news.slick({
      infinite: false,
      variableWidth: true,
      slidesToShow: 5,
      responsive: [
        {
          breakpoint: 2150,
          settings: {
            slidesToShow: 4
          }
        },
        {
          breakpoint: 1730,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 1290,
          settings: {
            slidesToShow: 2
          }
        },
        {
          breakpoint: 840,
          settings: {
            slidesToShow: 1
          }
        },
        {
          breakpoint: 499,
          settings: {
            slidesToShow: 1.25
          }
        },
      ]
    });
  }
}