export default class Menu {
  constructor() {
    this.hamburger = $(".header:not(.sub-menu) .hamburger__wrapper");
    this.nav = $(".header:not(.sub-menu) .header__nav");
    this.searchToggle = $(".header .search-toggle");
    this.search = $(".header .header__search");
    this.transparentLogo = $(".header__transparent-logo");

    this.subHamburger = $('.sub-menu .hamburger__wrapper');
    this.subMenuNav = $('.sub-menu .header__nav');

    this.childrenToggle = $(".children-toggle");

    this.initMenu();
    this.initSubMenu();
    this.initChildrenToggle();
  }

  initMenu() {
    this.hamburger.on("click", () => {
      const windowWidth = $(window).width();

      this.hamburger.children().toggleClass("active");
      this.nav.slideToggle(300);
      this.nav.removeClass("fade");

      if (this.hamburger.children().hasClass("active")) {
        this.searchToggle.addClass("visible");
        this.search.addClass("visible");
      } else {
        this.searchToggle.removeClass("visible");
        this.search.removeClass("visible");
        this.hamburger.blur()
      }

      if (windowWidth >= 960) {
        this.searchToggle.removeClass("active");
        this.search.slideUp(300);
      }

      if (
        this.hamburger.children().hasClass("active") ||
        this.searchToggle.hasClass("active")
      ) {
        this.transparentLogo.addClass("active");
      } else {
        this.transparentLogo.removeClass("active");
      }
    });

    this.searchToggle.on("click", () => {
      const windowWidth = $(window).width();

      this.searchToggle.toggleClass("active");

      if (windowWidth >= 960) {
        this.hamburger.children().removeClass("active");
        this.nav.slideUp(300);
      }

      if ($(".header").hasClass("header--shrink") && windowWidth >= 960) {
        this.search.slideToggle(300);
      } else if (this.searchToggle.hasClass("active")) {
        this.search.css({
          display: "block",
          width: 0,
          overflow: "hidden",
          opacity: 1
        });

        setTimeout(() => {
          this.search.css("width", "100%");
        }, 0);
      } else {
        this.search.css({
          opacity: 0
        });

        setTimeout(() => {
          this.search.css({
            display: "none"
          });
        }, 300);
      }

      if (
        !$(".header").hasClass("header--shrink") &&
        this.searchToggle.hasClass("active") &&
        windowWidth >= 960
      ) {
        this.nav.addClass("fade");
      } else {
        this.nav.removeClass("fade");
      }

      if (
        this.hamburger.children().hasClass("active") ||
        this.searchToggle.hasClass("active")
      ) {
        this.transparentLogo.addClass("active");
      } else {
        this.transparentLogo.removeClass("active");
      }
    });

    $(window).on("resize", () => {
      if (this.hamburger.children().hasClass("active")) {
        this.searchToggle.addClass("visible");
        this.search.addClass("visible");
      } else {
        this.searchToggle.removeClass("visible");
        this.search.removeClass("visible");
      }

      if (this.searchToggle.hasClass('active')) {
        this.searchToggle.removeClass('active');
        this.search.removeAttr('style');
      }
    });
  }

  initSubMenu() {
    this.subHamburger.on('click', () => {
      this.subHamburger.children().toggleClass('active');
      this.subMenuNav.slideToggle(300);
    });
  }

  initChildrenToggle() {
    this.childrenToggle.on('click', (e) => {
      const $toggle = $(e.currentTarget);

      $toggle.toggleClass('open');
      $toggle.next().slideToggle(300);

      if ($toggle.hasClass('open')) {
        $toggle.attr('aria-label', 'Hide Children');
      } else {
        $toggle.attr('aria-label', 'Show Children');
      }
    });
  }
}
