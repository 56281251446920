export default class MoonChallenge {
  constructor() {
    // convert numbers to numbers with commas
    function numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }

    // override the Miles input to only allow numbers
    function validate(evt, element) {
      var theEvent = evt || window.event
      var max_chars = 1

      if (element.value.length > max_chars) {
        element.value = element.value.substr(0, max_chars)
      }

      // Handle paste
      if (theEvent.type === 'paste') {
        key = evt.clipboardData.getData('text/plain')
      } else {
        // Handle key press
        var key = theEvent.keyCode || theEvent.which
        key = String.fromCharCode(key)
      }
      var regex = /[0-9]|\./
      if (!regex.test(key) || key == '.') {
        theEvent.returnValue = false
        if (theEvent.preventDefault) theEvent.preventDefault()
      }
    }

    const milesInput = document.querySelector('#text_field_242')

    if (typeof milesInput != 'undefined' && milesInput != null) {
      milesInput.addEventListener('keypress', function (e) {
        validate(e, milesInput)
      })
    }

    // update the tracker
    // const totalMiles = 154 // use for testing.
    const totalMiles = 238900 // miles to the moon.
    const milesLogged = $('.tracker__item--mileslogged').data('tracked')
    const milesToGo = totalMiles - milesLogged
    let trackerPos = (milesLogged / totalMiles) * 100 + 20 + '%'

    // update numbers
    $('.tracker__number--mileslogged').text(milesLogged)
    $('.tracker__number--milestogo').text(milesToGo)

    // move cape kid based on window size.
    if (totalMiles > milesLogged && (milesLogged / totalMiles) * 100 > 24) {
      trackerPos = (milesLogged / totalMiles) * 100 + '%'
    } else if (milesLogged >= totalMiles) {
      $('.miles-tracker__space').addClass('houston-we-landed')
      trackerPos = '100%'
    } else {
      trackerPos = '30%'
    }

    // count down timer
    const countDownDate = new Date('May 6, 2023 0:0:0').getTime()
    const now = new Date().getTime()
    const distance = countDownDate - now
    const days = Math.floor(distance / (1000 * 60 * 60 * 24))
    const hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
    )
    let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))

    const minuteUpdate = function () {
      $('.tracker__number--minutes').text(minutes)
    }

    $('.tracker__number--days').text(days)
    $('.tracker__number--hours').text(hours)
    $('.tracker__number--minutes').text(minutes)

    // update minutes every 20s
    const setMinutes = setInterval(minuteUpdate, 20000)

    // If the count down is finished, write some text
    // if (distance < 0) {
    //   clearInterval(x)
    //   document.getElementById('demo').innerHTML = 'EXPIRED'
    // }

    // Update the logo
    setTimeout(() => {
      $('.orbit__tracker').css('left', trackerPos)
    }, '1000')
    // Count up numbers
    const animationDuration = 2000
    const frameDuration = 1000 / 60
    const totalFrames = Math.round(animationDuration / frameDuration)
    const easeOutQuad = (t) => t * (2 - t)

    const animateCountUp = (el) => {
      let frame = 0
      const countTo = parseInt(el.innerHTML, 10)
      const counter = setInterval(() => {
        frame++
        const progress = easeOutQuad(frame / totalFrames)
        const currentCount = Math.round(countTo * progress)

        if (parseInt(el.innerHTML, 10) !== currentCount) {
          el.innerHTML = numberWithCommas(currentCount)
        }

        if (frame === totalFrames) {
          clearInterval(counter)
        }
      }, frameDuration)
    }

    const countupEls = document.querySelectorAll('.tracker__number')
    countupEls.forEach(animateCountUp)

    // reload the page after submit.
    $('.moonchallenge-form button').click(function () {
      setTimeout(() => {
        location.reload()
      }, '1500')
    })
  }
}
