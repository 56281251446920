import Vimeo from '@vimeo/player'

export default class VideoGallery {
  constructor() {
    if ($('.video-gallery').length > 0) {
      $('.video-card').each((index, video) => {
        const $video = $(video);

        if ($video.find('video').length > 0) {
          $video.find('.video-card__video, .video-card__title').on('click', () => {
            $video.find('.video-card__overlay').fadeIn(300, () => {
              this.playVideo($video);
            })
          });
        } else if ($video.find('iframe').attr('src').includes("youtube")) {
          $video.find('.video-card__video, .video-card__title').on('click', () => {
            $video.find('.video-card__overlay').fadeIn(300, () => {
              $video.find('iframe').attr('src', $video.find('iframe').attr('src').replace("autoplay=0", "autoplay=1"))
            });
          });
        } else {
          $video.find('.video-card__video, .video-card__title').on('click', () => {
            const vimeoVideo = new Vimeo($video.find('iframe').attr('id'));

            $video.find('.video-card__overlay').fadeIn(300, () => {
              vimeoVideo.play();
            });
          });
        }
      });

      $('.video-card__close').on('click', event => {
        const $parent = $(event.currentTarget).parent();
        $parent.fadeOut(300);

        const $iframe = $parent.find('iframe')
        const $video = $parent.find('video')

        if ($iframe.length > 0) {
          $iframe.attr("src", $iframe.attr("src").replace("autoplay=1", "autoplay=0"))
        }

        if ($video.length > 0) {
          $video[0].pause()
        }
      })
    }
  }

  playVideo(video) {
    const file = video.find('video');
    file[0].play();
  }
}