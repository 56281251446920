import 'selectize';
import 'selectize/dist/css/selectize.css';

export default class Dropdowns {
  constructor() {
    this.dropdowns = $('.dropdown-list select');

    this.dropdowns.each((index, element) => {
      this.initDropdown($(element));
    });
  }

  initDropdown(dropdown) {
    dropdown.selectize({
      maxItems: 1,
      allowEmptyOption: true
    });
  }
}