export default class Search {
  constructor() {
    this.switch = $('.search-results__switch').find('span');


    this.switch.each((index, control) => {
      $(control).on('click', () => {
        $(this.switch).toggleClass('active');
        $('.unfiltered').toggleClass('hidden');
        $('.filtered').toggleClass('hidden');
        $('#filter-id').attr('disabled', !$('#filter-id').attr('disabled'));
      })
    })
  }
}