export default class ProgramHero {
  constructor() {
    this.hero = $('.program-hero');
    this.slides = $('.program-hero__slides');
    this.text = $('.program-hero__dynamic-text');
    this.textItem = $('.program-hero__text-item');

    this.initSlider();
  }

  initSlider() {
    this.slides.on('init', () => {
      const currentWidth = $('.program-hero__text-item.active').outerWidth();

      this.text.width(currentWidth);

      this.textItem.each((index, item) => {
        const $item = $(item),
          text = $item.text(),
          splitText = text.split(''),
          parsed = '';

        $item.empty();
        for (let i = 0; i < splitText.length; i++) {
          const tag = $('<span/>');
          tag.text(splitText[i]);
          $item.append(tag);
        }
      });

      $('.program-hero__text-item:not(.active) span')
        .css('opacity', 0);
    });

    this.slides.on('beforeChange', (e, slick, fromSlide, toSlide) => {
      this.textItem.removeClass('active');
      this.textItem.find('span')
        .css('opacity', 0);

      const nextText = this.textItem.eq(toSlide);
      const nextWidth = nextText.outerWidth();
      this.text.width(nextWidth);

      setTimeout(() => {
        nextText.addClass('active');
        const timer = 400;
        let lettersShown = 0;

        const interval = setInterval(() => {
          nextText.find('span').eq(lettersShown)
            .css('opacity', 1);
          lettersShown++;

          if (lettersShown === nextText.text().length) {
            clearAnimation();
          }
        }, timer / nextText.text().length);

        function clearAnimation() {
          clearInterval(interval);
        }
      }, 500);
    });

    this.slides.slick({
      arrows: false,
      autoplay: true,
      fade: true,
      pauseOnHover: false,
      speed: 200
    });
  }
}