export default class Accordions {
  constructor() {
    this.accordions = $('.accordions');

    this.accordions.each((index, accordions) => {
      this.accordion = $(accordions).find('.accordion__title');

      $(this.accordion).each((index, accordion) => {
        $(accordion).on('click', (element) => {
          this.toggle(element.currentTarget);
        })
      })
    })
  }

  toggle(accordion) {
    this.indicator = $(accordion).find('.accordion__indicator');
    this.content = $(accordion).next();
    $(this.content).slideToggle();
    $(this.indicator).toggleClass('open');
  }
}