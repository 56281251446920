export default class FeaturedRaces {
  constructor() {
    this.slider = $('.featured-races__slider');

    $('body').on('init', () => {
      $('.slick-next').on('click', () => {
        $('.slick-next').blur();
      });

      $('.slick-prev').on('click', () => {
        $('.slick-prev').blur();
      });
    })

    if (this.slider.length) {
      this.slider.on('init', () => {
        const cardHeight = this.slider.find('.slick-track').height();
        this.slider.find('.slick-slide').height(cardHeight);
      });

      this.slider.slick({
        edgeFriction: 0.5,
        infinite: false,
        slidesToShow: 3.5,
        waitForAnimate: false,
        responsive: [
          {
            breakpoint: 1440,
            settings: {
              slidesToShow: 2.5
            }
          },
          {
            breakpoint: 900,
            settings: {
              slidesToShow: 1.25
            }
          },
          {
            breakpoint: 500,
            settings: {
              slidesToShow: 1.1
            }
          }
        ]
      });

      $(window).on('resize', () => {
        this.slider.find('.slick-slide').height('auto');

        const cardHeight = this.slider.find('.slick-track').height();
        this.slider.find('.slick-slide').height(cardHeight);
      });
    }
  }
}